<template>
  <base-modal :id="partnerModalName" :full="true">
    <add-edit-page className="full-white">
      <template #header="{ validation }">
        <sticky-header title="Edit Partner" classes = "partner-header">
          <h4>{{ form.profile.firstName }} {{ form.profile.lastName }}</h4>
          <div class="document-toolbar">
            <div class="document-actions">
              <div class="button-group">
                <button v-if="segmentPII" class="button" @click="enableEditing()">Edit</button>
                <button v-if="!editingEnabled || !segmentPII" class="button"
                  :disabled="checkValidation(validation) || validateDates(validation) || licStateError || licNumberError || emailError || phoneError || poError || ssnError || poError1 || isAddressFetchingOrError || partnerDetailsSaved"
                  @click="handleSubmit(validation)">Save</button>
                <a class="button secondary" @click="cancel(validation, true)">Cancel</a>
              </div>
              <p class="message-align" :class="update.success ? 'success' : 'error'" v-if="displayMessage">{{
                update.message }}</p>
            </div>
          </div>
        </sticky-header>
      </template>
      <template>
        <div class="grid">
          <div class="col-7">
            <div class="spread">
              <div class="module-form">
                <h3>BASIC INFO</h3>
                <div class="para-group">
                <form-input v-model="form.profile.firstName" v-if="getRole !== 'COORDINATOR'" :disabled="segmentPII" name="First Name " label="First Name" placeholder="First Name" required rules="specialChar|trailSpaces|hasNum|min:2|max:50" />
                <form-input v-model="form.profile.lastName" v-if="getRole !== 'COORDINATOR'" :disabled="segmentPII" label="Last Name" placeholder="Last Name" rules="specialChar|trailSpaces|hasNum|min:2|max:50" required />
                <form-input v-model="form.profile.phone" :disabled="segmentPII" name="Phone" v-maska="'(###) ###-####'" label="Phone Number" type="text" @input="phoneCheck" rules="phone" placeholder="Phone Number" required />
                <p class="error-message" v-if="phoneError">Phone Number already exists</p>
                <form-input v-model="form.profile.email" :disabled="segmentPII" name="Email" label="Email" type="email"  @input="emailCheck" placeholder="Email" required />
                  <p class="error-message" v-if="emailError">Email Address already exists</p>
                </div>
              </div>
              <div class="module-form" v-if="partner.address1 && partner.postalCode">
                <h3>ADDRESS</h3>
                <div class="para-group">
                  <form-input v-model="form.address.address1" :disabled="editingEnabled" name="address"  label="Address1" @input="poCheck1" required rules="trailSpaces" />
                  <p class="error-message" v-if="addressErrorMsg && addressErrorMsg.length"> {{ addressErrorMsg }}</p>
                  <div v-if="showThis && addressSuggestions.length > 0" :class="{'suggestBlock':editingEnabled}" class="box">
                    <div v-for="(item, index) in addressSuggestions" :key="index">
                      <p class="suggestion-item" @click="setAddress(item.item)">{{ item.name }}</p>
                    </div>
                  </div>
                  <form-input v-model="form.address.address2" maxlength="100" name="address" @input="poCheck" label="Address2" rules="trailSpaces"
                    :disabled="editingEnabled"
                    />
                    <p class="error-message" v-if="poError">Please enter a valid home address other than PO box</p>
                  <form-input v-model="form.address.city" name="city" label="City" required
                    rules="trailSpaces|addressText" :disabled="disableAddress || editingEnabled || tempDisableInput" />
                  <div class="field">
                    <label>State*
                      <form-input v-model="maskState" name="state" rules="trailSpaces" v-if="editingEnabled" disabled />
                      <region-select v-if="!editingEnabled" v-model="form.address.state" :country="form.address.country"
                        :region="form.address.state" placeholder="Select State" autocomplete disable-placeholder
                        :disabled="disableAddress || tempDisableInput"
                         />
                    </label>
                  </div>
                  <form-input v-model="form.address.postalCode" name="zip" label="Zip" required rules="zip"
                  :disabled="disableAddress || editingEnabled"
                  />
                  <!-- <form-input v-model="form.address.county" name="county" label="County" required /> -->
                  <div class="field">
                    <label>Country*
                      <form-input v-model="maskState" name="state" rules="trailSpaces" v-if="editingEnabled" disabled />
                      <country-select v-if="!editingEnabled" v-model="form.address.country"
                        :country="form.address.country" :white-list="['US']" top-country="US" autocomplete
                        :disabled="disableAddress || editingEnabled" />
                    </label>
                  </div>
                </div>
              </div>
              <div class="module-form" v-if="licenseExist">
                <h3>DRIVERS LICENSE</h3>
                <div class="field">
                  <label>Drivers License State
                    <form-input v-model="maskState" name="state" rules="trailSpaces" v-if="editingEnabled" disabled />
                    <region-select v-if="!editingEnabled" v-model="form.license.state" :disabled="editingEnabled || editDL"
                      :region="form.license.state" placeholder="Drivers License State" autocomplete disable-placeholder
                      @input="validateLicenseState" />
                  </label>
                  <p class="error-message" v-if="licStateError">License state doesn't match with the number</p>
                </div>
                <form-input v-model="form.license.number" :disabled="editingEnabled || editDL" placeholder="Drivers License Number"
                  name="licenseNumber" label="Drivers License Number"
                   ref="license" :checkDriverNumber="driverNumberTest"
                  @input="inputLicense" />
                  <p class="error-message" v-if="licNumberError">{{errorLicense}}</p>
                <p class="label margin-bottom-0">Drivers License Expiration</p>
                <div class="field">
                  <Datepicker v-model="form.license.expiration" :format="editingEnabled ? '**** *** **' : 'yyyy-MMM-dd'"
                    @selected="() => validator = false" :disabled="editingEnabled || editDL" :disabled-dates="disabledDates">
                  </Datepicker>
                </div>
              </div>
              <div class="module-form" v-if="(personalIdent && getRole !== 'ONBOARDING_SPECIALIST') && getRole !== 'COORDINATOR'">
                <h3>PERSONAL IDENTIFYING</h3>
                <div v-if="partner.dateOfBirth" class="dob-field-bottom">
                  <p class="label margin-bottom-0">Date of Birth</p>
                  <div class="field">
                    <Datepicker v-model="form.profile.dateOfBirth" :disabled="editingEnabled"
                      :format="editingEnabled ? '**** *** **' : 'yyyy-MMM-dd'" @selected="dateCheck"></Datepicker>
                  </div>
                  <p class="error-message" v-if="ageValidationMessage">Age must be above 18 years</p>
                </div>
                <form-input v-if="einExist" v-model="form.profile.ein" :disabled="editingEnabled" placeholder="EIN"
                  type="text" name="EIN" :rules="'length:10'" label="EIN" required />
                <form-input v-if="partner.ssn && editingEnabled" :value="'***-**-' + this.partner.ssn.slice(-4)"
                  :disabled="editingEnabled" placeholder="SSN" type="text" label="Social Security Number" />
                <form-input v-if="partner.ssn && !editingEnabled" v-model="form.profile.ssn" :disabled="editingEnabled"
                  maxLength="9" placeholder="SSN" type="text" name="SSN" @input="checkSsn" label="Social Security Number"
                  required />
                <p class="error-message" v-if="ssnError">{{ssnErrorMsg}}</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </add-edit-page>
  </base-modal>
</template>

<script>
import StickyHeader from "@/components/layout/StickyHeader";
import FormInput from "@/components/forms/fields/FormInput";
import AddEditPage from "@/components/layout/AddEditPage";
import BaseModal from "@/components/modals/BaseModal";
import micromodal from "micromodal";
import { maska } from "maska";
import { restApi } from "@/http/http.config";
import Datepicker from "vuejs-datepicker";
import { DriversLicenseValidation } from "@/util/validator";
import { formatPhone, formatDate } from "@/filters";
import { encodeWithParam,encodeWithParamutf } from "@/util/Base64Encoding";
import Base64 from "base-64";
import { encrypt } from "@/filters";
import { mapGetters } from "vuex";

export default {
  name: "PartnerProfileEdit",
  directives: { maska },
  components: { AddEditPage, FormInput, StickyHeader, BaseModal, Datepicker },
  data: function () {
    return {
      driverNumberTest: false,
      errorLicense:'',
      ageValidationMessage: false,
      editingEnabled: true,
      maskState: '******',
      showThis:false,
      form: {
        profile: {
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          ein: "",
          ssn: "",
          dateOfBirth: "",
        },
        address: {
          address1: "",
          address2: "",
          city: "",
          state: "",
          postalCode: "",
          country: "US",
          addressID: "",
          county: ""
        },
        license: {
          state: "",
          number: "",
          expiration: "",
          id: ""
        },
      },
      displayMessage: false,
      update: {
        success: "",
        message: ""
      },
      validator: true,
      formError: false,
      licStateError: false,
      licNumberError:false,
      addressSuggestions: [],
      timeout: "",
      disableAddress: true,
      emailError:false,
      phoneError:false,
      ssnError:false,
      poError:false,
      poError1:false,
      tempDisableInput:true, // this is to disable a field temporarily 
      segmentPII: true,
      addressErrorMsg: "",
      isAddressFetching: false,
      selectedAddress: "",
      editDL:false,
      partnerDetailsSaved:false
    };
  },
  props: {
    partnerModalName: {
      type: String,
      required: false,
      default: "editPartnerModal",
    },
    partner: {
      type: Object,
      required: true,
      default: () => { }
    },
    partnerId: {
      required: true,
      default: "",
    }
  },
  computed: {
    ...mapGetters(["getPermission"]),
    einExist() {
      return this.$props.partner?.businessDetails ? this.$props.partner?.businessDetails[0]?.ein ? true : false : false;
    },
    licenseExist() {
      return this.$props.partner?.driverLicense ? this.$props.partner?.driverLicense[0]?.number ? true : false : false;
    },
    disabledDates() {
      return { to: new Date() }
    },
    searchInput() {
      return this.form.address.address1;
    },
    personalIdent() {
      return this.$props.partner.ssn || this.$props.partner.dateOfBirth || this.einExist
    },
    getRole() {
      return localStorage.getItem('role');
    },
    isAddressFetchingOrError() {
      if(this.form.address.addressID==null || this.form.address.addressID==undefined){
        return false 
      }
      else{
        return Boolean(this.isAddressFetching || this.addressErrorMsg?.length || this.addressSuggestions?.length || !this.searchInput?.length);
      }
    }
  },
  watch: {
    partnerId() {
      this.prefillForm();
    },
    searchInput(newText, oldText) {
      if (oldText !== "" && this.hasNonStarOrSpace(oldText)) {
        if (newText !== oldText) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          const value = newText;
          if(value==""){
            this.addressSuggestions = [];
          }
          else{
            this.getAddressSuggestions(value);
          }
        }, 500);
      }
    },
  },
  methods: {
    dateCheck(val) {
      this.validator = true
      var today = new Date();
      var birthDate = new Date(val);
      var age = today.getFullYear() - birthDate.getFullYear();
      if (age < 18) {
        this.ageValidationMessage = true;
      } else {
        this.ageValidationMessage = false;
        return this.validator = false
      }
    },
    poCheck1(value){
      this.addSugg();
      const restrictedAddress = ["pob", "pobo", "pobox", "bo", "box", "PO", "PO", "BOX", "PO B", "PO BO", "P.O. BOX", "P.O BOX", "PO. BOX", "POBOX", "PO BOX", "PO    BOX", "P.O BO", "PO. B", "P O Box", " PO  BOX", "PO BOX", "PO  B", "PO  BO", "P O BOX", "P O B", "P OB", "P O B", "P.O. B", "PO B", "P O", "P O", "P.O", "P.O ", "P.O  ", " P.O", "  P.O", "P.O B", "P.O  B", "P.O.  BO", "P.O.  BOX", "P.O.  B", " P.O.", "  P.O.", "  P.O. B", "  P.O. BO", "  P.O.  BOX", "p.o", "po", "p.o.", " po", "  po", "  po box", "p o", " p.o.", "p o box", "P.o", "p.O", "Po", "p.O"];

      // if (restrictedAddress.includes(value.toUpperCase().trim())) {
        if (value.split(' ').some((strItem) => restrictedAddress.includes(strItem))){
        this.poError1 = true
        
      } else {
        this.poError1 = false
        
      }
    },
    poCheck(value){
      const restrictedAddress = ["pob", "pobo", "pobox", "bo", "box", "PO", "PO", "BOX", "PO B", "PO BO", "P.O. BOX", "P.O BOX", "PO. BOX", "POBOX", "PO BOX", "PO    BOX", "P.O BO", "PO. B", "P O Box", " PO  BOX", "PO BOX", "PO  B", "PO  BO", "P O BOX", "P O B", "P OB", "P O B", "P.O. B", "PO B", "P O", "P O", "P.O", "P.O ", "P.O  ", " P.O", "  P.O", "P.O B", "P.O  B", "P.O.  BO", "P.O.  BOX", "P.O.  B", " P.O.", "  P.O.", "  P.O. B", "  P.O. BO", "  P.O.  BOX", "p.o", "po", "p.o.", " po", "  po", "  po box", "p o", " p.o.", "p o box", "P.o", "p.O", "Po", "p.O"];

      // if (restrictedAddress.includes(value.toUpperCase().trim())) {
        if (value.split(' ').some((strItem) => restrictedAddress.includes(strItem))){
        this.poError = true
        
      } else {
        this.poError = false
        
      }
    },
    validateDates(val) {
      return !val.pristine ? false : this.validator;
    },
    enableEditing() {
      if ((this.getPermission('editSegmentCandidatePII') && !this.$props.partner.dms_id) 
          || (this.getPermission('editSegmentPartnerPII') && this.$props.partner.dms_id)) {
        this.segmentPII = false;
      } else {
        if(this.$props.partner.dms_id || !this.getPermission("editDL"))
        {
          this.editDL=true;
        }
        this.segmentPII = false;
        this.editingEnabled = false;
      }
      this.prefillForm();
    },
    prefillForm() {
      this.form.profile.firstName = this.$props.partner.firstName;
      this.form.profile.lastName = this.$props.partner.lastName;
      this.form.profile.phone = formatPhone(this.$props.partner.phone);
      this.form.profile.email = this.$props.partner.email;
      this.form.profile.dateOfBirth = this.$props.partner.dateOfBirth ? formatDate(this.$props.partner.dateOfBirth) : "";

      this.form.license.state = this.$props.partner?.driverLicense[0]?.state;
      this.form.license.expiration = this.$props.partner?.driverLicense[0]?.expiration ? formatDate(this.$props.partner.driverLicense[0].expiration) : "";
      this.form.license.number = this.$props.partner?.driverLicense[0]?.number;
      this.form.license.id = this.$props.partner?.driverLicense[0]?.id;
      this.form.address.state = this.$props.partner.state;
      this.form.address.address2 = this.$props.partner.address2;



      this.form.address.addressID = this.$props.partner.addressID;
      this.form.address.county = this.$props.partner.county;

      this.form.address.address1 = this.editingEnabled ? this.piiMasking(this.$props.partner.address1) : this.$props.partner.address1;
      this.form.address.address2 = this.editingEnabled ? this.piiMasking(this.$props.partner.address2) : this.$props.partner.address2;
      this.form.address.city = this.editingEnabled ? this.piiMasking(this.$props.partner.city) : this.$props.partner.city;
      this.form.address.postalCode = this.editingEnabled ? this.piiMasking(this.$props.partner.postalCode) : this.$props.partner.postalCode;
      this.form.license.number = this.editingEnabled ? this.piiMasking(this.$props.partner?.driverLicense[0]?.number) : this.$props.partner?.driverLicense[0]?.number;

      this.form.profile.ein = this.editingEnabled ? this.piiMasking(this.$props.partner?.businessDetails[0]?.ein) : this.$props.partner?.businessDetails[0]?.ein;
      if(this.$props.partner.ssn) {
        this.form.profile.ssn = this.editingEnabled ? this.piiMasking(this.$props.partner.ssn) :this.ssnReformat(this.$props.partner.ssn)
      }

    },

    piiMasking(value) {
      return value?.substring(0, value.length)?.replace(/[a-z\d/-]/gi, "*");
    },
    cancel(validation, reset) {
      micromodal.close(this.partnerModalName);
      document.body.style.overflow = "visible";
      validation.reset();
      this.editingEnabled = true;
      this.validator = true;
      this.licStateError = false;
      this.disableAddress = false;
      this.addressSuggestions = [];
      this.emailError = false
      this.ssnError = false
      this.phoneError = false
      this.licNumberError = false
      this.segmentPII = true
      if (reset) {
        this.prefillForm();
      }
    },
    checkValidation(val) {
      for (let err in val.errors) {
        if (val.errors[err].length > 0) {
          this.formError = true;
          return true
        }
      }
      this.formError = false;
      return false
    },
    async emailCheck(val) {
      const result = await restApi.post("/partner/checkEmail", {
         param: Base64.encode(JSON.stringify({ email: val.toLowerCase() })),
      })

      const valueEntered = val.length > 0
      const initialEmail = (this.$props.partner.email == val)

      const emailTaken = result?.data?.success && valueEntered && !initialEmail
      if (emailTaken) {
        this.emailError = true
      } else return this.emailError = false
    },
    async phoneCheck(val) {
      val = val.replace(/\D/g, '')
      const result = await restApi.post("/partner/checkPhone", { param: Base64.encode(JSON.stringify({ phone: val })) })
      const hasEnteredValue = val.length > 0
      const phoneExists = result?.data?.success
      
      if (hasEnteredValue && phoneExists) {
        this.phoneError = true
      } else return this.phoneError = false
    },

    ssnReformat(val){
      let sanitizedSSN = val.replace(/\D/g, '');
      return `${sanitizedSSN.slice(0, 3)}-${sanitizedSSN.slice(3, 5)}-${sanitizedSSN.slice(5)}`;

    },

    async checkSsn(val) {
      let sanitizedSSN = val.replace(/\D/g, '');
      const maxLength = 11;
      const maxLengthWithoutHyphen = 9;
      if (val.length <= maxLength) {
        this.ssnError=true;
        this.ssnErrorMsg='';
        if (sanitizedSSN.length > 3 && sanitizedSSN.length <= 5) {
          this.form.profile.ssn = `${sanitizedSSN.slice(0, 3)}-${sanitizedSSN.slice(3)}`;
        } else if (sanitizedSSN.length > 5) {
          this.form.profile.ssn = `${sanitizedSSN.slice(0, 3)}-${sanitizedSSN.slice(3, 5)}-${sanitizedSSN.slice(5)}`;
        } else {
          this.form.profile.ssn = sanitizedSSN;
        }
      }
      else {
        this.form.profile.ssn = val.slice(0, maxLength)
      }
      if (val.length === maxLength && sanitizedSSN.length === maxLengthWithoutHyphen) {
        let ssnCheck = await encrypt(val.replace(/\D/g, ''))
        const data = await restApi.post("/employee/checkSSN", encodeWithParam({ ssn: ssnCheck }));
         this.ssnError=false;
        if(Number(data.data.result.count) > 0){
          this.ssnError=true;
          this.ssnErrorMsg='Duplicate SSN'
        }
      }
    },
    addSugg(){
      this.showThis = true
    },
    async handleSubmit(validation) {
      this.editingEnabled = true;
      this.partnerDetailsSaved = true;
      const partnerObj = {
        partnerId: this.$props.partner.id,
        ...this.form
      }
     partnerObj.profile.email = partnerObj.profile.email.toLowerCase()
      if ((this.getPermission('editSegmentCandidatePII') && !this.$props.partner.dms_id) || 
          (this.getPermission('editSegmentPartnerPII') && this.$props.partner.dms_id)) {
        const { firstName, lastName, phone, email } = partnerObj.profile
        const encodedData = encodeWithParamutf({ firstName, lastName, phone: phone.replace(/\D/g, ''), email, partnerId: partnerObj.partnerId, isCandidate: this.$props.partner.dms_id ? false : true })
        setTimeout(() => { this.updateApiCall(encodedData, validation, "segment");}, 1000)
      } else { 
        partnerObj.license.expiration = this.formatCalendarDate(partnerObj.license.expiration);
        partnerObj.profile.dateOfBirth = this.formatCalendarDate(partnerObj.profile.dateOfBirth);
        if( partnerObj.profile.ssn) {
          partnerObj.profile.ssnFour = partnerObj.profile.ssn.substr(partnerObj.profile.ssn.length - 4);
          partnerObj.profile.ssn = await encrypt(partnerObj.profile.ssn.replace(/-/g, ''))
        }
        const encodedData = encodeWithParamutf({ ...partnerObj, role: localStorage.getItem('role'), isCandidate: this.$props.partner.dms_id ? false : true });
        setTimeout(() => { this.updateApiCall(encodedData, validation, "complete");}, 1000)
      }
    },
    async updateApiCall(encodedData, validation, type) {
      if (!this.formError) {
        const api = type === "segment" ? "/partner/updatePartnerSegmentDetails" : "/partner/updatePartner"
        await restApi.post(api, encodedData)
          .then(({ data }) => {
              this.updDisplayParams(data.success, data.message, validation)
          })
      }
    },
    updDisplayParams(success, message, validation) {
      this.displayMessage = true;
      this.update.success = success;
      this.update.message = message;
      setTimeout(() => {
        this.displayMessage = false;
        this.update = { success: "", message: "" };
        success ? this.cancel(validation, false) : "";
        this.$emit("refetch");
        this.$emit("statusupdated");
        document.body.style.overflow = "visible";
      }, 2000);
    },
    trailSpaces(value) {
    const start = /^([ ]+)/
    const end = /([ ]+$)/
    return start.test(value) || end.test(value);
    },
    formatCalendarDate(value) {
      const date = new Date(value).toLocaleDateString('en-US');
      const [month, day, year] = date.split('/');
      return value ? [year, month, day].join('-') : null;
    },
    validateLicenseState() {
      const valid = DriversLicenseValidation(this.$refs.license._props.value, this.form.license.state);
      this.licStateError = !valid
      if(this.errorLicense == "Drivers License Number already exists.")
      this.licNumberError = false;

      if(this.licStateError == false && this.form.license.number){
        this.inputLicense(this.form.license.number);

        if(!this.ageValidationMessage)
        this.validator=false
      }

    },
    async inputLicense(value) {
       await restApi.post(`/partner/checkValueExists`, { 
      "param": window.btoa(JSON.stringify({ type: 'license', value: value, dlState : this.form.license.state }))
  }).then(({ data }) => {
        if(data.result.exists && value.length > 1){
            this.driverNumberTest = true;
            this.licNumberError = true;
            this.licStateError = false;
            this.errorLicense = "Drivers License Number already exists."
           }else{
              const valid = DriversLicenseValidation(value, this.form.license.state); 
              if (valid && this.licStateError) {
                this.licStateError = false;
                this.licNumberError = false;
            }else if(value.length > 0 && !valid) {
               this.driverNumberTest = true;
              this.licNumberError = true;
                  this.errorLicense = "License doesn't appear to be valid."
            }else if(value.length < 1) {
              this.licNumberError = true;
                  this.errorLicense = "Drivers License Number is required*"
            }
            else if(value.length > 0){ 
            let result = this.trailSpaces(value); 
            if(result == true){
              this.driverNumberTest = true;
                this.licNumberError = result;
                this.errorLicense = "Can't contain white space at the start or end"
            }else{
              this.driverNumberTest = false;
              this.licStateError = false;
                this.licNumberError = false;
            }      
        }
            else{
              this.driverNumberTest = false;
              this.licStateError = false;
                this.licNumberError = false;
            }
           }
         })     
      },
    
    getAddressSuggestions(searchText) {
      if (!this.editingEnabled && searchText && searchText.trim() !== this.selectedAddress?.trim()) {
        const searchParams = encodeWithParam({ search: searchText });
        const regex = new RegExp(/^(PO.*BOX*)/);
        this.addressSuggestions = [];
        this.addressErrorMsg = "";
        const restrictedAddress = ["PO", "BOX", "PO B", "PO BO", "P.O. BOX", "P.O BOX", "PO. BOX", "POBOX", "PO BOX", "PO BOX", "P.O BO", "PO. B", "P O Box", " PO BOX", "PO BOX", "PO B", "PO BO", "P O BOX", "P O B", "P OB", "P O B", "P.O. B", "PO B", "P O", "P O", "P.O", "P.O ", "P.O ", " P.O", " P.O", "P.O B", "P.O B", "P.O. BO", "P.O. BOX", "P.O. B", " P.O.", " P.O.", " P.O. B", " P.O. BO", " P.O. BOX", "p.o", "po", "p.o.", " po", " po", " po box", "p o", " p.o.", "p o box", "P.o", "p.O", "Po", "p.O"];
        
        if (!restrictedAddress.includes(searchText?.trim().toUpperCase()) && searchText.trim().toUpperCase().search(regex) === -1) {
          this.isAddressFetching = true;
          restApi.post("/employee/getAddressSuggestions", searchParams)
            .then((data) => {
              if (data?.data?.result?.length > 0) {
                this.addressSuggestions = data?.data?.result.map((item) => {
                  return {
                    name: `${item.streetLine}, ${item.city}, ${item.state}, ${item.zipcode}`,
                    item,
                  };
                });
              } else {
                this.addressErrorMsg = "Please enter a valid home address";
              }
              this.disableAddress = true;
              this.isAddressFetching = false;
        })
        } else {
          this.addressErrorMsg = "Please enter a valid home address other than PO box";
          this.disableAddress = true;
        }
      }
    },
    setAddress(data) {
      if (Object.keys(data).length > 0) {
        this.form.address.address1 = data.streetLine;
        this.form.address.address2 = data.secondary;
        this.form.address.city = data.city;
        //this.form.address.county = data.county;
        this.form.address.state = data.state;
        this.form.address.postalCode = data.zipcode;
        this.selectedAddress = data.streetLine;
      }
      this.addressSuggestions = [];
    },
    hasNonStarOrSpace(inputString) {
      return /[^* ]/.test(inputString);
    },
  }
};
</script>

<style scoped>
.date-pick-width {
  width: 424px;
}

.input-icon-max-width {
  padding-left: 90%;
}

.message-align {
  margin-top: 6px;
  margin-bottom: -12px;
}

.error {
  color: var(--error-40);
}

.success {
  color: var(--success-40);
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.dob-field-bottom {
  margin-bottom: 16px;
}

.box {
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  padding: 5px;
  font-family: "Open Sans";
  position: absolute;
  background-color: white;
  margin-top: -15px;
  z-index: 4;
}

.suggestion-item {
  padding: 5px;
  cursor: pointer;
  margin-bottom: 0;
}

.suggestion-item:hover {
  background-color: lightgray;
}</style>